import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'

import { Container, Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import styled from 'styled-components'
import sharePostOn from '../helpers/sharePost';
import Colors from '../constants/Colors';
import twitterIcon from '../images/twitter.svg';
import facebookIcon from '../images/facebook.svg';
import linkedinIcon from '../images/linkedin.svg'
import getLastAuthorForPost from '../helpers/getLastAuthorForPost'
import saveEmail from '../helpers/saveEmail'
/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/

const Article = styled.article`
    margin: 0 auto;
    font-size: 2rem;
    line-height: 1.7em;
    @media (max-width: 500px) {
        font-size: 1.8rem;
    }
    
`;

const PostPreview = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 800px) {
        flex-direction: column-reverse;
    }
`;


const PostFeatureImage = styled.figure`
    margin-bottom: 48px;
    img {
        margin: 0 0 3vw;
        width: 100%;
        height: 500px;
        object-fit: cover;
    }
`;

const PostHeader = styled.div`
    padding-left: 10%;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 64px;
    @media (max-width: 800px) {
        flex-direction: column;
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 20px;
    }
`;
const PostDetails = styled.div`
    max-width: 720px;
    margin: 0 auto;
    @media (max-width: 800px) {
        max-width: 100%;
        margin: 0px;

    }
`;
const PostSocial = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 100px;
    @media (max-width: 800px) {
        margin-left: 0px;
        flex-direction: row;
        width: 100%;
        margin-top: 40px;
    }
`;
const SocialIcon = styled.img`
    width: 24px;
    height: 24px;
    margin-bottom: 20px;
    cursor: pointer;
    @media (max-width: 800px) {
      margin-right: 20px;
    }
`;


const PostTitle = styled.h2`
    font-size: 7rem;
    margin-bottom: 10px;
    margin-top: 20px;
    letter-spacing: -0.25rem;
    color: ${Colors.PARAGRAPH};
    font-weight: 800;
    line-height: 1.125;
    @media (max-width: 500px) {
        margin: 0.8em 0;
        font-size: 3.4rem;
        margin-top: 0px;

    }
`;


const PostExcerpt = styled.p`
    font-weight: 400;
    color: ${Colors.PARAGRAPH};
    opacity: 0.8;
    line-height: 150%;
    margin-bottom: 20px;
`;
const PostAuthors = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

const PostAuthor = styled(Link)`
    display: flex;
    margin-top: 24px;
    align-items: center;
`;
const PostAuthorImage = styled.img`
    width: 48px;
    height: 48px;
    border-radius: 50%;
`;
const PostAuthorName = styled.p`
    margin-bottom: 0px;
    margin-left: 10px;
    color: ${Colors.PARAGRAPH};
    font-size: 14px;
    opacity: 0.75;
`;

const PostContent = styled.section`
    max-width: 720px;
    margin: 0 auto;
    background: #fff;
`;


const Post = ({ data, location }) => {
    const post = data.ghostPost
    //state
    const [email, setEmail] = useState('');
    const [confirmed, setConfirmed] = useState(false);
    const [error, setError] = useState(false);
    console.log({
        post
    })

    const factCheckedAuthor = getLastAuthorForPost(post.authors);
    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
            />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout
                headerBackText='All Posts'
                headerBackLink='/'
                headerButtonText='Get a demo'
                headerButtonLink='/'
                onFooterEmailChange={value => setEmail(value)}
                footerEmail={email}
                footerError={error}
                footerSubmit={(e) => saveEmail(e, email, setConfirmed, setError)}
                footerEmailConfirmed={confirmed}
            >
                <Container>
                    <Article>
                        <PostPreview>

                            <PostHeader>
                                <PostDetails>
                                    <PostTitle data-testid='title'>{post.title}</PostTitle>
                                    <PostExcerpt data-testid='excerpt'>{post.excerpt}</PostExcerpt>
                                    <PostAuthors>
                                        <PostAuthor to={`/author/${post.primary_author.slug}`}>
                                            <PostAuthorImage data-testid='authorImage' src={post.primary_author.profile_image} />
                                            <PostAuthorName data-testid='authorName'>by {post.primary_author.name}</PostAuthorName>
                                        </PostAuthor>
                                        {
                                            factCheckedAuthor &&

                                            <PostAuthor to={`/author/${factCheckedAuthor.slug}`}>
                                                <PostAuthorName data-testid='authorName'>Fact checked by {factCheckedAuthor.name}</PostAuthorName>
                                            </PostAuthor>
                                        }
                                    </PostAuthors>
                                </PostDetails>
                                <PostSocial>
                                    <SocialIcon
                                        src={linkedinIcon}
                                        alt="LinkedIn"
                                        onClick={() => sharePostOn('linkedin')}
                                    />
                                    <SocialIcon
                                        src={facebookIcon}
                                        alt="Facebook"
                                        onClick={() => sharePostOn('facebook')}
                                    />
                                    <SocialIcon
                                        src={twitterIcon}
                                        alt="Twitter"
                                        onClick={() => sharePostOn('twitter')}
                                    />
                                </PostSocial>
                            </PostHeader>
                            {
                                post.feature_image ?
                                    <PostFeatureImage>
                                        <img data-testid='image' src={post.feature_image} alt={post.title} />
                                    </PostFeatureImage> :
                                    null
                            }
                        </PostPreview>

                        <PostContent>
                            {/* The main post content */}
                            <section
                                data-testid='html'
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            />
                        </PostContent>
                    </Article>
                </Container>

            </Layout>
        </>
    );
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
            excerpt: PropTypes.string.isRequired,
            primary_author: PropTypes.shape({
                profile_image: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired
            }).isRequired
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`
