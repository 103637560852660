
const baseSocialUrl = {
    //share url of said social media site
    facebook: `https://www.facebook.com/sharer.php?u=`,
    twitter: `https://twitter.com/intent/tweet?url=`,
    linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=`
}

export default (social = 'facebook') => {
    //shares current page url on the social
    const currentURL = window.location.href;
    const baseUrl = baseSocialUrl[social] || baseSocialUrl['facebook'];
    return window.open(`${baseUrl}${currentURL}`, '_blank')
}
